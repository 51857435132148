@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/link';

.title {
  @include heading(base);
  margin-bottom: 0.5rem;
}

.seller-info-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  button {
    margin-left: auto;

    @include breakpoint(xlarge, min) {
      max-width: 10rem;
    }
  }
}

.seller-info {
  flex: 1;

  h2 {
    @include heading();
    margin-bottom: 0.5rem;
  }

  a {
    @include heading($font-size: base);
    transition: all 0.25s;

    &:hover {
      @include link();
    }
  }

  &-rating {
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
    align-items: flex-start;

    @include breakpoint(large, min) {
      flex-wrap: nowrap;
    }

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.1rem;

      @include breakpoint(large, min) {
        width: 1rem;
        height: 1rem;
      }
    }

    span {
      width: 100%;
      margin-top: 0.25rem;
      @include type($size: tiny, $color: copy);
    }
  }
}

.seller-photo {
  cursor: pointer;
  position: relative;
  min-width: 4rem;
  min-height: 4rem;
  width: 4rem;
  height: 4rem;

  img {
    border-radius: 50%;
    overflow: hidden;
  }

  &-last-active {
    text-align: center;
    position: absolute;
    bottom: -0.6rem;
    white-space: nowrap;
    width: 6rem;
    margin-left: auto;
    margin-right: auto;
    left: -0.5rem;
    right: 0;
    text-align: center;

    span {
      text-align: center;
    }
  }
}

.seller-action-button-container {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;

  @include breakpoint(small, max) {
    margin-top: 0.5rem;
    width: 100%;
    margin-left: 0;

    button {
      margin: 0;
    }

    button:not(:first-of-type) {
      margin-left: auto;
    }
  }
}

.seller-link-button-container {
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;

  :nth-child(1) {
    width: 100%;
  }

  @include breakpoint(small, max) {
    margin-top: 0.5rem;
  }
}

.image-active-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.pill {
  margin-top: -1rem;
  z-index: 1;
}
