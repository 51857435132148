.container {
  width: 100%;
  padding: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(300px, 25%);
  gap: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

.booksSection {
  overflow-x: auto;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  padding: 1rem;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
}

.booksGrid {
  display: flex;
  gap: 1rem;
  min-width: min-content;
}

.sellerSection {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.sellerDetails {
  display: flex;
  gap: 1.25rem;
  align-items: flex-start;
  margin-top: 0.75rem;
}

.sellerImage {
  border-radius: 50%;
  position: relative;
  min-width: 5rem;
  min-height: 5rem;
  width: 5rem;
  height: 5rem;
}

.sellerInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.followButton {
  margin-top: 0.25rem;
  margin-left: 0.25rem;
}
